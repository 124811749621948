import React, { useEffect, useState } from "react";
// import { Btn } from "../form-comp/form-component";
import { useTranslation } from "react-i18next";
import "./hero.css";

const HeroComp = () => {
  const { t } = useTranslation();
  const [
    ,
    // heroText
    setHeroText,
  ] = useState("");

  useEffect(() => {
    setHeroText(t("heroP"));
    // eslint-disable-next-line
  }, [t]);

  return (
    <div>
      <div className="hero-header">
        <div className="hero-header_line"></div>
        <div className="hero-head_text">
          <h1>
            {t("heroP")} <span>{t("heroSpan1")}</span> {t("heroP4")}
          </h1>
          <p>
            {" "}
            {t("heroP2")} <span>1000</span> {t("heroP3")}
          </p>
        </div>
      </div>
      {/* <div className="hero-btn">
        <Btn btnClass={"btn-trans"} btnText={t("signup")} href={"#sign-up"} />
      </div> */}
    </div>
  );
};

export default HeroComp;
