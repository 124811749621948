import { Route, Routes } from "react-router-dom";
import Landing from "./pages/landing";
import { Suspense } from "react";
// import About from "./pages/about";
import Layout from "./components/layout/layout";
// import NoPage from "./pages/no-page";
// import SignupPage from "./pages/signup-page";

function App() {
  return (
    <Suspense fallback={null}>
    <div>
      <Routes>
        <Route element={<Layout />}>
          <Route index element={<Landing />} />
          {/* <Route path="/aboutus" element={<About />} />
          <Route path="/signup" element={<SignupPage />} />
          <Route path="*" element={<NoPage />} /> */}
        </Route>
      </Routes>
    </div>
    </Suspense>
  );
}

export default App;
