import React, { useEffect, useState } from "react";
import "./form.css";
import { motion } from "framer-motion";
import close from "../../assets/images/close.png";
import load from "../../assets/images/spin.gif";

export const Btn = ({ btnClass, btnText, btnClick, href, loading, disabled, err }) => {
  const submitBtn = (e) => {
    e.preventDefault();
    if (btnClick) {
      btnClick();
    }
  };

  return (
    <div>
      {href && (
        <a href={href} style={{ textDecoration: "none" }}>
          <motion.button className={btnClass} whileTap={{ scale: 0.85 }}>
            {btnText}
          </motion.button>
        </a>
      )}
      {btnClick && (
        <motion.button
          className={disabled ? `opacity ${btnClass}` : btnClass}
          whileTap={{ scale: 0.85 }}
          onClick={(e) => submitBtn(e)}
        >
          {loading ? <img src={load} alt="loading" /> : btnText}
        </motion.button>
      )}
      {err && <p style={{color: "red", fontSize: "12px", textAlign: "left",width: "100%"}}>{err}</p>}
    </div>
  );
};

export const FormInput = ({ inputLabel, err, ...rest }) => {
  const [isTyping, setIsTyping] = useState(false);
  const [typingTimeout, setTypingTimeout] = useState(null);
  const borderStyle = {
    border: "1px solid var(--style, #F7931E)",
    borderRadius: "10px",
  };

  const handleInput = () => {
    setIsTyping(true);
    // Clear any previous timeout to reset the debounce timer
    if (typingTimeout) {
      clearTimeout(typingTimeout);
    }
    // Set a new timeout to set `isTyping` to false after 2 second of inactivity
    const timeout = setTimeout(() => {
      setIsTyping(false);
    }, 2000);

    setTypingTimeout(timeout);
  };

  const handleFocus = () => {
    setIsTyping(true);
  };

  const handleBlur = () => {
    setIsTyping(false);
    if (typingTimeout) {
      clearTimeout(typingTimeout);
    }
  };

  useEffect(() => {
    // Cleanup the timeout on component unmount
    return () => {
      if (typingTimeout) {
        clearTimeout(typingTimeout);
      }
    };
  }, [typingTimeout]);

  return (
    <div className="form-input">
      <label>{inputLabel}</label>
      <div style={isTyping ? borderStyle : null}>
        <input
          {...rest}
          onFocus={handleFocus}
          onBlur={handleBlur}
          onInput={handleInput}
        />
      </div>
      {err && <p style={{color: "red", fontSize: "12px", textAlign: "left",width: "100%"}}>{err}</p>}
    </div>
  );
};


export const SuccessAlert = ({message, success, setSuccess}) => {

    const clickClose = () => {
        setSuccess(false);
    }

    useEffect(() => {
      setInterval(() => {
        setSuccess(false);
      }, 80000);
    // eslint-disable-next-line
    }, [success])
    

    return (
        success && <div className="success-alert">
            <p>{message}</p> 
            <img src={close} alt="x" onClick={clickClose} />
        </div>
    )
}


export const validatePhoneNumber = (number) => {
    const phoneRegex = /^.{10,}$/; // Matches exactly 11 digits
    return phoneRegex.test(number);
};

export const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Basic email validation
    return emailRegex.test(email);
};

export const isEveryPropertyFull = (obj) => 
  Object.entries(obj).every(([key, value]) => value);




export const ReadMoreText = ({ text, slideClick }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  // Toggle between expanded and shortened text
  const toggleText = () => {
    setIsExpanded((prev) => !prev);
    if(slideClick){
      slideClick();
    }
  };

  return (
    <div className="read-more">
      <h3>
        {isExpanded ? text : `${text.slice(0, 20)}...`}
        <button
          onClick={toggleText}
          style={{
            marginLeft: "10px",
            background: "none",
            border: "none",
            color: "#F15A24",
            cursor: "pointer",
            fontWeight: "bold",
          }}
        >
          {isExpanded ? "Read Less" : "Read More"}
        </button>
      </h3>
    </div>
  );
};
