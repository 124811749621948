import React, { useEffect, useState } from 'react';
import Hero from '../components/hero/hero';
// import ComingSoon from '../components/coming-soon/coming-soon';
// import AboutUs from '../components/about/aboutus';
import Signup from '../components/sign-up/sign-up';
import axios from 'axios';

const Landing = () => {

  const timeHit = new Date().toISOString();

  const [location, setLocation] = useState({});

  useEffect(() => {
    const fetchLocation = async () => {
      try {
        const response = await axios.get("https://ipapi.co/json/");
        setLocation({
          city: response.data.city,
          region: response.data.region,
          country: response.data.country_name,
        });
      } catch (error) {
        console.error("Error fetching location:", error);
      }
    };

    fetchLocation();
  }, []);
  console.log(location);
  

  useEffect(() => {
    // Check if visit has already been tracked in the current session
    if(sessionStorage.getItem("visitTracked") && sessionStorage.getItem("time") < timeHit){
       sessionStorage.removeItem("visitTracked");
    }
    if (!sessionStorage.getItem("visitTracked")) {
      const now = new Date();
      sessionStorage.setItem("visitTracked", true);
      sessionStorage.setItem("time", new Date(now.getTime() + 24 * 60 * 60 * 1000).toISOString());
    }
    // eslint-disable-next-line
  }, []);

  // console.log(sessionStorage.getItem("visitTracked"), sessionStorage.getItem("time"));
  

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <div>
        <Hero />
        <Signup />
        {/* <ComingSoon />
        <AboutUs /> */}
    </div>
  )
}

export default Landing