import React from 'react';
import Navbar from '../nav/nav-bar';
import { Outlet, useLocation } from 'react-router-dom';
// import Contact from '../contact/contact';

const Layout = () => {

    const pathname = useLocation().pathname;

  return (
    <div>
        {pathname !== "/" && <Navbar />}
        <Outlet />
        {/* <Contact /> */}
    </div>
  )
}

export default Layout