// src/i18n.js
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';


// Get the persisted language from localStorage or default to 'en'
const savedLanguage = localStorage.getItem('language') || 'en';

// Define translations
const resources = {
    en: {
        translation: {
            home: "Home",
            signup: "Sign up",
            countdownT: "Coming in:",
            days: "Days",
            hours: "Hours",
            minutes: "Minutes",
            seconds: "Seconds",   
            heroP: "Join our wait list today and enjoy",
            heroSpan1: "a free movie",
            heroP4: "on us!",
            heroP2: "This offer is limited to the first",
            heroP3: "users. Don’t miss out!",
            joinUsP: "Join us today! and unlock exclusive benefits, rewards, and more. Let’s get started",
            name: "enter full name:",
            phone: "enter phone number:",
            email: "enter email address:",
            emailErr: "Invalid email address.",
            allErr: "Please fill all available fields",
            success: "Congratulations!!! you’ve successfully signed up",
            about: "About us",
            coming: "Coming Soon!",
            comingP: "Buckle up, our blockbuster series are on the way this festive season. We have special packages for you!!",
            trailer: "Watch Trailer",
            aboutP: "Circuits is a transactional video-on-demand platform designed as a",
            aboutSpan1: "virtual cinema",
            aboutP2: "to exhibit",
            aboutSpan2: "african",
            aboutP3: "films and series for global audience.",
            learn: "learn more",
            contactus: "Contact Us",
            circuitsIntro: "INTRODUCTION TO CIRCUITS",
            introP1: "Circuits is a transactional video-on-demand platform designed as a virtual cinema to exhibit African films and series for a global audience.",
            introP2: "Circuits aims to provide brand new, premium films and series produced in Africa. Customers will have access to brand new, premium African titles that can be screened within 24 hours of purchase via the platform for a fraction of the price of a cinema ticket from the comfort of their homes.",
            introP3: "Circuits aims to launch 5-10 new titles monthly for users to enjoy. Movies and series can be watched by paying a fee to access the movie for a limited time. The app launches on December 20th on all devices including phones, laptops and Smart TVs globally. Users can make payment in their local currencies via a variety of payment methods available on the platform.",
            asiriSyp: "In this a typical tale of politics, love, and intrigue, two kingdoms contemplate a royal union for different motives. A hidden romance, a lurking killer, and a power struggle raise the stakes. Will love triumph or lead to ruin?",
            atikoSyp: "Lifelong friends  Ajoke and Lape, facing hardships, discover a surprising lineage common to them. Their pursuit of change strains family bonds and challenges village perceptions, forcing them to hide their secret at a high cost.",
            ruthSyp: "In the thrilling underground fighting scene,  a brave young woman fights for her life amid a deadly feud. With danger everywhere, she must outsmart her enemies in a high-stakes game where secrets run deep.",
            weekendSyp: "Nikiya, an orphan longing for family, pressures her fiancé Luc to introduce her to his estranged relatives. Initially charmed by their warmth, she soon discovers the dark secrets that pushed Luc away. As danger looms, Nikiya must fight to protect herself from the sinister truth.",
            sevenDoorsSyp: "When Adedunjoye, a simple family man, is unexpectedly crowned the ruler of Ilara, he finds himself in a web of deadly schemes and spiritual reckonings. Tasked with marrying six more wives to atone for his ancestors' sins, Adedunjoye's love for his wife, Amaka, is put to the ultimate test. As tragedy strikes, he battles to save his kingdom and legacy.",
        },
    },
    pt: {
        translation: {
            home: "Lar",
            signup: "Cadastre-se",
            countdownT: "Entrando:",
            days: "Dias",
            hours: "horas",
            minutes: "minutos",
            seconds: "segundos",   
            heroP: "Junte-se hoje à nossa lista de espera e aproveite", 
            heroSpan1: "um filme grátis",
            heroP4: "em nós!",
            heroP2: "Esta oferta está limitada ao primeiro",
            heroP3: "usuários. Não perca!",
            joinUsP: "Junte-se a nós hoje! e desbloqueie benefícios, recompensas exclusivas e muito mais. Vamos começar",
            name: "digite o nome completo:",
            phone: "digite o número do telefone:",
            email: "digite o endereço de e-mail:",
            emailErr: "Endereço de e-mail inválido.",
            allErr: "Por favor preencha todos os campos disponíveis",
            success: "Parabéns!!! você se inscreveu com sucesso",
            about: "Sobre nós",
            coming: "Em breve!",
            comingP: "Apertem os cintos, a nossa série de sucesso está a caminho nesta época festiva. Temos pacotes especiais para si!!",
            trailer: "Assistir ao trailer",
            aboutP: "Circuits é uma plataforma transacional de vídeo on-demand concebida como um",
            aboutSpan1: "cinema virtual",
            aboutP2: "expor",
            aboutSpan2: "africano",
            aboutP3: "filmes e séries para audiência global.",
            learn: "saber mais",
            contactus: "Contate-nos",
            circuitsIntro: "INTRODUÇÃO AOS CIRCUITOS", 
            introP1: "Circuits é uma plataforma transacional de vídeo on-demand concebida como um cinema virtual para exibir filmes e séries africanas a um público global.",
            introP2: "A Circuits tem como objetivo fornecer novos filmes e séries premium produzidos em África. Os clientes terão acesso a novos títulos africanos premium que podem ser exibidos no prazo de 24 horas após a compra através da plataforma por uma fração do preço de um bilhete de cinema, no conforto das suas casas.",
            introP3: "A Circuits pretende lançar 5 a 10 novos títulos mensalmente para os utilizadores desfrutarem. Os filmes e séries podem ser vistos mediante o pagamento de uma taxa para ter acesso ao filme por tempo limitado. A aplicação será lançada a 20 de dezembro em todos os dispositivos, incluindo telefones, computadores portáteis e Smart TVs em todo o mundo. Os utilizadores podem efetuar pagamentos nas suas moedas locais através de diversos métodos de pagamento disponíveis na plataforma.",
            asiriSyp: "Nesta história típica de política, amor e intriga, dois reinos contemplam uma união real por motivos diferentes. Um romance escondido, um assassino à espreita e uma luta pelo poder aumentam os riscos. O amor triunfará ou levará à ruína?",
            atikoSyp: "Amigos de longa data, Ajoke e Lape, enfrentando dificuldades, descobrem uma surpreendente linhagem comum a eles. A sua busca pela mudança prejudica os laços familiares e desafia as percepções da aldeia, forçando-os a esconder o seu segredo a um custo elevado.",
            ruthSyp: "Na emocionante cena de luta subterrânea, uma jovem corajosa luta pela sua vida no meio de uma rivalidade mortal. Com o perigo por todo o lado, ela deve ser mais esperta que os seus inimigos num jogo de alto risco onde os segredos são profundos.",
            weekendSyp: "Nikiya, uma órfã que anseia por uma família, pressiona o seu noivo Luc para que a apresente aos seus parentes distantes. Inicialmente encantada com o calor deles, depressa descobre os segredos obscuros que afastaram Luc. À medida que o perigo se aproxima, Nikiya tem de lutar para se proteger da verdade sinistra.",
            sevenDoorsSyp: "Quando Adedunjoye, um simples homem de família, é inesperadamente coroado governante de Ilara, vê-se envolvido numa teia de esquemas mortais e ajustes de contas espirituais. Com a tarefa de casar com mais seis esposas para expiar os pecados dos seus antepassados, o amor de Adedunjoye pela sua esposa, Amaka, é posto à prova. À medida que a tragédia acontece, ele luta para salvar o seu reino e o seu legado.",
        },
    },
    fr: {
        translation: {
            home: "Maison",
            signup: "S'inscrire",
            countdownT: "À venir:",
            days: "Jours",
            hours: "heures",
            minutes: "minutes",
            seconds: "secondes",  
            heroP: "Rejoignez notre liste d'attente aujourd'hui et profitez",
            heroSpan1: "un film gratuit",
            heroP4: "sur nous !",
            heroP2: "Cette offre est limitée au premier",
            heroP3: "utilisateurs. Ne ratez rien!",
            joinUsP: "Rejoignez-nous dès aujourd'hui ! et bénéficiez d'avantages exclusifs, de récompenses et bien plus encore. Commençons",
            name: "entrez le nom complet :",
            phone: "entrez le numéro de téléphone :",
            email: "entrez l'adresse e-mail :",
            emailErr: "Adresse e-mail invalide.",
            allErr: "Veuillez remplir tous les champs disponibles",
            success: "Félicitations!!! vous êtes inscrit avec succès",
            about: "À propos de nous",
            coming: "À venir!",
            comingP: "Attachez vos ceintures, nos séries à succès sont en route pour cette période des fêtes. Nous avons des packages spéciaux pour vous !!",
            trailer: "Regarder la bande annonce",
            aboutP: "Circuits est une plateforme de vidéo à la demande transactionnelle conçue comme un",
            aboutSpan1: "cinéma virtuel",
            aboutP2: "exposer",
            aboutSpan2: "africain",
            aboutP3: "films et séries pour un public mondial.",
            learn: "apprendre encore plus",
            contactus: "Contactez-nous",
            circuitsIntro: "INTRODUCTION AUX CIRCUITS", 
            introP1: "Circuits est une plateforme de vidéo à la demande transactionnelle conçue comme un cinéma virtuel pour présenter des films et séries africains à un public mondial.",
            introP2: "Circuits a pour objectif de proposer des films et séries inédits et de qualité supérieure produits en Afrique. Les clients auront accès à des titres africains inédits et de qualité supérieure qui pourront être visionnés dans les 24 heures suivant l'achat via la plateforme pour une fraction du prix d'un billet de cinéma depuis le confort de leur domicile.",
            introP3: "Circuits a pour objectif de lancer 5 à 10 nouveaux titres par mois pour le plus grand plaisir des utilisateurs. Les films et séries peuvent être visionnés en payant un droit d'accès au film pendant une durée limitée. L'application sera lancée le 20 décembre sur tous les appareils, y compris les téléphones, les ordinateurs portables et les téléviseurs intelligents dans le monde entier. Les utilisateurs peuvent effectuer des paiements dans leur monnaie locale via une variété de méthodes de paiement disponibles sur la plateforme.",
            asiriSyp: "Dans ce conte typique de politique, d'amour et d'intrigue, deux royaumes envisagent une union royale pour des motifs différents. Une histoire d'amour cachée, un tueur en embuscade et une lutte de pouvoir font monter les enjeux. L'amour triomphera-t-il ou mènera-t-il à la ruine?",
            atikoSyp: "Ajoke et Lape, amis de toujours, se découvrent une étonnante ascendance commune face aux difficultés. Leur quête du changement met à rude épreuve les liens familiaux et remet en cause les perceptions du village, les obligeant à cacher leur secret au prix de lourdes pertes.",
            ruthSyp: "Dans cette scène de combat souterrain palpitante, une jeune femme courageuse se bat pour sa vie au milieu d'une querelle mortelle. Le danger est omniprésent et elle doit déjouer ses ennemis dans un jeu à enjeux élevés où les secrets sont profondément ancrés.",
            weekendSyp: "Nikiya, une orpheline en mal de famille, fait pression sur son fiancé Luc pour qu'il la présente à ses proches. D'abord charmée par leur chaleur, elle découvre bientôt les sombres secrets qui ont éloigné Luc. Alors que le danger menace, Nikiya doit se battre pour se protéger de la sinistre vérité.",
            sevenDoorsSyp: "Lorsqu'Adedunjoye, un père de famille simple, est couronné de façon inattendue à la tête d'Ilara, il se retrouve pris dans un réseau de complots mortels et de comptes à rendre spirituels. Chargé d'épouser six autres femmes pour expier les péchés de ses ancêtres, l'amour d'Adedunjoye pour sa femme, Amaka, est mis à rude épreuve. Alors que la tragédie frappe, il se bat pour sauver son royaume et son héritage.",
        },
    },
};

// Initialize i18next
i18n.use(initReactI18next).init({
    resources,
    lng: savedLanguage, // Default language
    fallbackLng: 'en', // Fallback language
    interpolation: {
        escapeValue: false, // React already escapes
    },
});

export default i18n;
